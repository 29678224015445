* {
  overscroll-behavior: none;
}
html[dir="rtl"] {
  font-size: 110% !important;
}
html[dir="ltr"] {
  font-size: 100% !important;
}

::-webkit-scrollbar {
  width: 6px !important;
}

.slick-dots li.slick-active button:before {
  color: #5078e1 !important;
}

/* this targets the default scrollbar (compulsory) */

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Hide Number Input Roller */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background   : rgba(0, 0, 0, 0.23) !important; */
  background: transparent !important;
  border-radius: 24px;
  transition: all 0.5s;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: rgba(146, 151, 165, 1) !important; */
  background: transparent !important;
}

/* this will style the thumb, ignoring the track */

::-webkit-scrollbar-button {
  background-color: transparent !important;
}

/* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
  background-color: transparent !important;
}

/* Previous Font-Family */
/* @font-face {
  font-family: "NotoSans-ExtraBold";
  src: url("./font/NotoSans-SemiBold.ttf");
}

@font-face {
  font-family: "NotoSans-Bold";
  src: url("./font/NotoSans-Medium.ttf");
}

@font-face {
  font-family: "NotoSans-SemiBold";
  src: url("./font/NotoSans-Regular.ttf");
}

@font-face {
  font-family: "NotoSans-Regular";
  src: url("./font/NotoSans-Light.ttf");
}

@font-face {
  font-family: "NotoSans-Italic";
  src: url("./font/NotoSans-Italic.ttf");
} */

/* Arabic Font-Family */
@font-face {
  font-family: "IBMPlexSans-Arabic-ExtraBold";
  src: url("./font/IBMPlexSansArabic-Bold.ttf");
}

@font-face {
  font-family: "IBMPlexSans-Arabic-Bold";
  src: url("./font/IBMPlexSansArabic-Bold.ttf");
}

@font-face {
  font-family: "IBMPlexSans-Arabic-SemiBold";
  src: url("./font/IBMPlexSansArabic-Bold.ttf");
}

@font-face {
  font-family: "IBMPlexSans-Arabic-Regular";
  src: url("./font/IBMPlexSansArabic-Regular.ttf");
}

@font-face {
  font-family: "IBMPlexSans-Arabic-Italic";
  src: url("./font/IBMPlexSansArabic-Light.ttf");
}

/* All Font-Family */
@font-face {
  font-family: "IBMPlexSans-All-ExtraBold";
  src: url("./font/IBMPlexSans-Bold.ttf");
}

@font-face {
  font-family: "IBMPlexSans-All-Bold";
  src: url("./font/IBMPlexSans-Bold.ttf");
}

@font-face {
  font-family: "IBMPlexSans-All-SemiBold";
  src: url("./font/IBMPlexSans-SemiBold.ttf");
}

@font-face {
  font-family: "IBMPlexSans-All-Regular";
  src: url("./font/IBMPlexSans-Regular.ttf");
}

@font-face {
  font-family: "IBMPlexSans-All-Italic";
  src: url("./font/IBMPlexSans-Italic.ttf");
}


/* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_9785">Text with this font applied</p> */
html {
  height: 100%;
  font-family: "NotoSans-All-Regular";
  src: url("./font/NotoSans-Regular.ttf");
  overscroll-behavior: none;
}

#root {
  height: 100%;
  overscroll-behavior: none;
}

.infinite-scroll-component {
  overflow: none !important;
}

.swiper-android .swiper-slide,
.swiper-wrapper {
  transform: scale(0.8)
}

.react-pdf__Page__canvas {
  width: 100%;
  height: 100%;
}

#pdf-download {
  /* display: none; */
}

.gJqVsw {
  display: none !important;
}

.react-simple-image-viewer__close {
  opacity: 1 !important
}

.styles-module_wrapper__1I_qj {
  background-color: rgb(70 66 66 / 90%) !important
}

.styles-module_navigation__1pqAE {
  line-height: auto !important;
  display: block !important;
  background: #000 !important;
  opacity: 0.4 !important;
  display: inline-flex !important;
  height: auto !important;
  padding: 10px 8px 18px !important;
}

.slick-dots li,
.slick-dots li.slick-active {
  margin: 0 1px !important;
  font-size: 1.2rem;
}

.slick-dots li button::before {
  content: '' !important;
  line-height: 8px !important;
  background-color: #bdbebf;
  padding: 0 !important;
  height: 6px !important;
  width: 6px !important;
  border-radius: 50%;
}

.slick-dots li.slick-active button::before {
  content: "";
  line-height: 16px;
  width: 16px !important;
  height: 6px;
  line-height: 26px !important;
  background-color: #5078E1;
  border-radius: 24px;
}

.bottom_bar {
  padding-bottom: calc(0.5 * env(safe-area-inset-bottom));
  cursor: pointer;
}

[dir="rtl"] .arrow {
  transform: rotate(180deg);
  text-align: right;
}

[dir="rtl"] .datepicker {
  position: absolute;
  left: 25px;
  top: 25%
}

[dir="rtl"] .dateTimePicker {
  position: absolute;
  top: 55%;
  left: 25px;
}

[dir="rtl"] .avatar {
  position: absolute;
  top: 0;
  right: -22px;
  height: 45px;
  width: 45px;
  background-color: #F2F4F7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
}

body {
  font-family: "NotoSans-All-Regular";
  src: url("./font/NotoSans-Regular.ttf");
  overscroll-behavior: none;
}

[dir="rtl"] .navbarArrow {
  font-size: 1rem;
  transform: rotate(180deg);
}
.MuiTypography-root {
  letter-spacing: 0;
}

#partner-video{
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}
